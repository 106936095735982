import React from "react";

import { useFeatureFlags } from "../../FeatureFlags";

import Home from "./Home";
import DoubleCharts from "./DoubleCharts";

export default function HomeScreen({ ...props }) {
  const featureFlags = useFeatureFlags();

  return featureFlags?.newHomepage ? (
    <DoubleCharts {...props} />
  ) : (
    <Home {...props} />
  );
}
