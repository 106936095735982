import React, { useEffect, useState, useRef, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  Carousel,
  Modal,
  Section,
  Stack,
  TaskStack,
  Typography,
  HeadingDetailed,
  Skeleton,
  ButtonRefactored
} from "@operata/adagio";
import CanvasDashboard from "../CanvasDashboard/CanvasDashboard";
import { Close, PlayCircleOutline, OpenInNew } from "@mui/icons-material";
import { fetchGroupDashboardDetails } from "../../actions/groups";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "./index.scss";
import { useFeatureFlags } from "../../FeatureFlags";
import Garvis from "../Garvis";
import { activeBISession } from "../../actions/auth";
import "../Iframe/Iframe.scss";
import "./index.scss";

let Home = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { charts } = useSelector(state => state.homepage);
  const biAuthSuccessful = useSelector(
    state => state.canvas.biAuthSuccessful,
    shallowEqual
  );

  const [isModalOpen, setModalOpen] = useState(false);

  const intervalRef = useRef(null);
  const attemptsRef = useRef(0);

  const [modalContent, setModalContent] = useState({
    header: "",
    content: ""
  });

  const featureFlags = useFeatureFlags();

  useEffect(
    () => {
      dispatch(fetchGroupDashboardDetails());

      intervalRef.current = setInterval(() => {
        attemptsRef.current += 1;
        dispatch(activeBISession());
      }, 1000 * 2);

      return () => {
        clearInterval(intervalRef.current);
      };
    },
    [dispatch]
  );

  useEffect(
    () => {
      if (biAuthSuccessful || attemptsRef.current > 10) {
        clearInterval(intervalRef.current);
      }
    },
    [biAuthSuccessful]
  );

  const handleExternalNavigate = url => {
    window.open(url, "_blank");
  };

  const handleNavigate = url => {
    history.push(url);
  };

  const handleShowArticle = id => {
    if (window.Intercom && id) {
      window.Intercom("showArticle", id);
    } else {
      console.error("[Operata] Intercom article could not be loaded");
    }
  };

  const openModal = (header, content) => {
    setModalContent({ header, content });
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const memorisedCanvasDashboard = useMemo(
    () => (
      <CanvasDashboard
        sliceId={charts?.["home_v3_agent_reported_issues_metric"]?.toString()}
        timeFilter={"28d"}
      />
    ),
    [charts]
  );

  return (
    <div className={"double-charts"}>
      <Stack direction="col" gap="medium">
        {featureFlags.garvis && <Garvis />}

        <Section>
          <Stack padding="medium" gap="small" direction="row">
            <Stack width={400} direction="column" gap="small">
              <HeadingDetailed
                level={3}
                title="Reported Issues and Insights (28 days)"
              />
              <Typography>
                <p>
                  Agent Reported Issues and Improvement Insights surface
                  opportunities to resolve issues for individual agents as well
                  as problems that may impact service and agent experience.
                  Analyze trends and reduce frequency to improve both agent and
                  customer experience.
                </p>
              </Typography>

              <Stack direction="row" gap="4">
                <ButtonRefactored
                  type="primary"
                  onClick={() => handleNavigate(`canvas/issues`)}
                >
                  Explore Dashboard
                </ButtonRefactored>
                <ButtonRefactored
                  iconAfter={<OpenInNew />}
                  onClick={() => handleShowArticle(10089660)}
                >
                  Learn More (Docs)
                </ButtonRefactored>
              </Stack>
            </Stack>
            <Stack direction="row" gap="small" flex height={"180px"}>
              {/* Seems like we had a hack to load one dashboard first */}
              {/* Agent Reported Issues (28 days) :pie: */}
              <div className={"double-charts__metric-chart"}>
                {memorisedCanvasDashboard}
              </div>
              {biAuthSuccessful && (
                <>
                  {/* Insights (28 days) :pie: */}
                  {/* # Calls [By Attribute] (28 days) :pie: */}
                  <CanvasDashboard
                    sliceId={
                      charts?.[
                        "home_v3_agent_reported_issues_chart"
                      ]?.toString() || "215011"
                    }
                    timeFilter={"28d"}
                  />
                </>
              )}
              {!biAuthSuccessful && (
                <>
                  {/*<Skeleton*/}
                  {/*  width={"100%"}*/}
                  {/*  height={180}*/}
                  {/*  loading*/}
                  {/*  text={"Agent Reported Issues"}*/}
                  {/*/>*/}
                  <Skeleton
                    width={"100%"}
                    height={180}
                    loading
                    text={"Improvement Insights"}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Section>

        {/* IT Optimize Technology */}
        <Section>
          <Stack padding="medium" gap="small" direction="row">
            <Stack width={400} direction="column" gap="small">
              <HeadingDetailed level={3} title="Technology CX Risk (28 days)" />
              <Typography>
                <p>
                  The IT Risk Score is the percentage of calls with IT Risk
                  Indicators, such as network, application or equipment
                  problems. Proactively lower the IT Risk Score to improve
                  customer and agent experience.
                </p>
              </Typography>
              <Stack direction="row" gap="4">
                <ButtonRefactored
                  type="primary"
                  onClick={() => handleNavigate(`canvas/technology`)}
                >
                  Explore Dashboard
                </ButtonRefactored>
                <ButtonRefactored
                  iconAfter={<OpenInNew />}
                  onClick={() => handleShowArticle(10089759)}
                >
                  Learn More (Docs)
                </ButtonRefactored>
              </Stack>
            </Stack>
            <Stack direction="row" gap="small" flex height={"180px"}>
              {biAuthSuccessful && (
                <>
                  {/* High IT CX Risk (28 days) :pie: */}
                  <div className={"double-charts__metric-chart"}>
                    <CanvasDashboard
                      sliceId={
                        charts?.["home_v3_technical_metric"]?.toString() ||
                        "214629"
                      }
                      timeFilter={"28d"}
                    />
                  </div>
                  {/* High IT CX Risk (28 days) */}
                  <CanvasDashboard
                    sliceId={
                      charts?.["home_v3_technical_chart"]?.toString() ||
                      "220220"
                    }
                    timeFilter={"28d"}
                  />
                </>
              )}
              {!biAuthSuccessful && (
                <>
                  <Skeleton
                    width={280}
                    height={180}
                    loading
                    text={"IT Risk Score"}
                  />
                  <Skeleton
                    width={"100%"}
                    height={180}
                    loading
                    text={"IT Risk Indicators"}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Section>

        {/* OPS Optimise - Customer Experience */}
        <Section>
          <Stack padding="medium" gap="small" direction="row">
            <Stack width={400} direction="column" gap="small">
              <HeadingDetailed
                level={3}
                title="Operational CX Risk (28 days)"
              />
              <Typography>
                <p>
                  The Ops Risk Score is the percentage of calls with Ops Risk
                  Indicators, such as call abandonment, no audio or missed
                  calls. Proactively lower the Ops Risk Score to improve
                  customer and agent experience.
                </p>
              </Typography>
              <Stack direction="row" gap="4">
                <ButtonRefactored
                  type="primary"
                  onClick={() => handleNavigate(`canvas/agentinteractions`)}
                >
                  Explore Dashboard
                </ButtonRefactored>
                <ButtonRefactored
                  iconAfter={<OpenInNew />}
                  onClick={() => handleShowArticle(10089761)}
                >
                  Learn More (Docs)
                </ButtonRefactored>
              </Stack>
            </Stack>
            <Stack direction="row" gap="small" flex height={"180px"}>
              {biAuthSuccessful && (
                <>
                  {/* Medium & High Ops CX Risk (28 days) :pie: */}
                  <div className={"double-charts__metric-chart"}>
                    <CanvasDashboard
                      sliceId={
                        charts?.["home_v3_operational_metric"]?.toString() ||
                        "214909"
                      }
                      timeFilter={"28d"}
                    />
                  </div>
                  {/* Ops CX Risk (28 days) */}
                  <CanvasDashboard
                    sliceId={
                      charts?.["home_v3_operational_chart"]?.toString() ||
                      "220222"
                    }
                    timeFilter={"28d"}
                  />
                </>
              )}
              {!biAuthSuccessful && (
                <>
                  <Skeleton
                    width={280}
                    height={180}
                    loading
                    text={"Ops Risk Score"}
                  />
                  <Skeleton
                    width={"100%"}
                    height={180}
                    loading
                    text={"Ops Risk Indicators"}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Section>

        {/* Coverage */}
        <Section>
          <Stack padding="medium" gap="small" direction="row">
            <Stack width={400} direction="column" gap="small">
              <HeadingDetailed
                level={3}
                title="Collection Coverage (28 days)"
              />
              <Typography>
                <p>
                  Collection Coverage is the percentage of Agents that Operata
                  is collecting data and insights from. Use Coverage Gap details
                  to improve Collection Coverage and accuracy for all metrics
                  across your contact center environments.
                </p>
              </Typography>
              <Stack direction="row" gap="4">
                <ButtonRefactored
                  type="primary"
                  onClick={() => handleNavigate(`canvas/coverage`)}
                >
                  Explore Dashboard
                </ButtonRefactored>
                <ButtonRefactored
                  iconAfter={<OpenInNew />}
                  onClick={() => handleShowArticle(10089764)}
                >
                  Learn More (Docs)
                </ButtonRefactored>
              </Stack>
            </Stack>
            <Stack direction="row" gap="small" flex height={"180px"}>
              {biAuthSuccessful && (
                <>
                  {/* Browser Coverage (28 days) :percentage text: */}
                  <div className={"double-charts__metric-chart"}>
                    <CanvasDashboard
                      title={"Test"}
                      sliceId={
                        charts?.["home_v3_coverage_metric"]?.toString() ||
                        "215020"
                      }
                      timeFilter={"28d"}
                    />
                  </div>
                  {/* Operata Coverage (28 days) */}
                  <CanvasDashboard
                    sliceId={
                      charts?.["home_v3_coverage_chart"]?.toString() || "214614"
                    }
                    timeFilter={"28d"}
                  />
                </>
              )}
              {!biAuthSuccessful && (
                <>
                  <Skeleton
                    width={280}
                    height={180}
                    loading
                    text={"Collection Coverage"}
                  />
                  <Skeleton
                    width={"100%"}
                    height={180}
                    loading
                    text={"Coverage Gap"}
                  />
                </>
              )}
            </Stack>
          </Stack>
        </Section>

        <Section>
          <Section.Header
            title="Explore Operata"
            description="Learn the basics, and access handy tips & resources"
          />
          <Section.Content>
            <TaskStack>
              <TaskStack.Item
                title="User guides"
                description="How to use Operata and explore the data elements we collect in detail."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate(
                    "https://help.operata.com/en/collections/2146543-user-guides"
                  )
                }
              />
              <TaskStack.Item
                title="FAQs"
                description="Commonly asked questions, hints and tips to get the most out of Operata."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate(
                    "https://help.operata.com/en/collections/1988587-faq"
                  )
                }
              />
              <TaskStack.Item
                title="Learn about agent investigation"
                description="Find out how you can analyse agent technical performance and activity by to troubleshoot issues."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate(
                    "https://help.operata.com/en/articles/6230386-playbook-4-agent-investigation"
                  )
                }
              />
              <TaskStack.Item
                title="Agent reported issues"
                description="Explore agent reports on service performance issues, and surface specific areas needing improvement."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate(
                    "https://help.operata.com/en/articles/6431953-playbook-7-agent-reported-issue-investigation"
                  )
                }
              />
              <TaskStack.Item
                title="Setup a workflow"
                description="Connect Operata to your systems. Send an email, push a webHook, generate an EventBridge notification or even send a Slack message."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate(
                    "https://help.operata.com/en/articles/3721528-workflow"
                  )
                }
              />
              <TaskStack.Item
                title="Help Center"
                description="Check out our articles with frequently asked questions, tips and tricks."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate("https://help.operata.com/en/")
                }
              />
              <TaskStack.Item
                title="Operata Documentation"
                description="Build upon your Operata Knowledge by reading our documentation."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate("https://docs.operata.com/docs")
                }
              />
              <TaskStack.Item
                title="Recent Articles"
                description="Read more on our latest improvements, product releases and contact center insights over on our Blog."
                icon={<OpenInNew />}
                onClick={() =>
                  handleExternalNavigate("https://operata.com/blog")
                }
              />
            </TaskStack>
          </Section.Content>
        </Section>
        <Section>
          <Section.Header
            title="Resources"
            description="Learn more about how to use Operata to its full potential"
          />
          <Section.Content>
            <Carousel>
              <Card
                onClick={() =>
                  openModal(
                    "1.1 Basic Navigation",
                    <iframe
                      src="https://player.vimeo.com/video/827908960?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                      width="960"
                      height="540"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="1.1 Basic Navigation"
                    />
                  )
                }
              >
                <Card.Thumbnail
                  image="https://static.operata.io/operata-assets/video-training-thumbnails/1-1.png"
                  icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                />
                <Card.Content title="1.1 Basic Navigation" />
              </Card>

              <Card
                onClick={() =>
                  openModal(
                    "1.3 Workflow",
                    <iframe
                      src="https://player.vimeo.com/video/827906577?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                      width="960"
                      height="540"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="1.3 Workflow"
                    />
                  )
                }
              >
                <Card.Thumbnail
                  image="https://static.operata.io/operata-assets/video-training-thumbnails/1-3.png"
                  icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                />
                <Card.Content title="1.3 Workflow" />
              </Card>

              <Card
                onClick={() =>
                  openModal(
                    "1.4 History",
                    <iframe
                      src="https://player.vimeo.com/video/830469041?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                      width="960"
                      height="540"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="1.4 History"
                    />
                  )
                }
              >
                <Card.Thumbnail
                  image="https://static.operata.io/operata-assets/video-training-thumbnails/1-4.png"
                  icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                />
                <Card.Content title="1.4 History" />
              </Card>

              <Card
                onClick={() =>
                  openModal(
                    "2.0 Canvas - Basic Navigation",
                    <iframe
                      src="https://player.vimeo.com/video/837820929?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                      width="960"
                      height="540"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="2.0 Canvas - Basic Navigation"
                    />
                  )
                }
              >
                <Card.Thumbnail
                  image="https://static.operata.io/operata-assets/video-training-thumbnails/2-0.png"
                  icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                />
                <Card.Content title="2.0 Canvas - Basic Navigation" />
              </Card>

              <Card
                onClick={() =>
                  openModal(
                    "2.1 - Agent and Technology Overview",
                    <iframe
                      src="https://player.vimeo.com/video/837822498?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                      width="960"
                      height="540"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="2.1 - Agent and Technology Overview"
                    />
                  )
                }
              >
                <Card.Thumbnail
                  image="https://static.operata.io/operata-assets/video-training-thumbnails/2-1.png"
                  icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                />
                <Card.Content title="2.1 - Agent and Technology Overview" />
              </Card>

              <Card
                onClick={() =>
                  openModal(
                    "3.1 Heartbeat explained",
                    <iframe
                      src="https://player.vimeo.com/video/830457520?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                      width="960"
                      height="540"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="3.1 Heartbeat explained"
                    />
                  )
                }
              >
                <Card.Thumbnail
                  image="https://static.operata.io/operata-assets/video-training-thumbnails/3-1.png"
                  icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                />
                <Card.Content title="3.1 Heartbeat explained" />
              </Card>

              <Card
                onClick={() =>
                  openModal(
                    "4.0 Simulate",
                    <iframe
                      src="https://player.vimeo.com/video/830935947?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                      width="960"
                      height="540"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      title="4.0 Simulate"
                    />
                  )
                }
              >
                <Card.Thumbnail
                  image="https://static.operata.io/operata-assets/video-training-thumbnails/4-0.png"
                  icon={<PlayCircleOutline sx={{ fontSize: 48 }} />}
                />
                <Card.Content title="4.0 Simulate" />
              </Card>
            </Carousel>
          </Section.Content>
        </Section>
      </Stack>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size="medium"
        closeIcon={<Close />}
      >
        <Modal.Header>{modalContent.header}</Modal.Header>
        <Modal.Content>{modalContent.content}</Modal.Content>
      </Modal>
    </div>
  );
};

Home.propTypes = {
  selectedTimeFilter: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired
};

Home.defaultProps = {
  selectedTimeFilter: "7d"
};

const mapDispatchToProps = {};

const mapStateToProps = state => ({
  userProfile: state.auth.profile
});

// eslint-disable-next-line no-class-assign
Home = connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);

// eslint-disable-next-line no-class-assign
Home = withRouter(Home);

export default Home;
