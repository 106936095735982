import React, { useEffect, useState } from "react";
import {
  ButtonRefactored as Button,
  PageHeading,
  Stack,
  Table,
  AddIcon,
  Badge
} from "@operata/adagio";
import CreateRoleModal from "./CreateRoleModal";
import { Condition, CreateDataRole, DataRole } from "../../models/dataRole";
import UpdateRoleModal from "./UpdateRoleModal";
import { UserProfile } from "../../models/user";
import { useDispatch } from "react-redux";
import { addUserToRole, fetchUsersForRole, removeUserFromRole, updateRole } from "../../actions/playbooks";
import EditConditionsModal from "./EditConditionsModal";

type RolesProps = {
  roles: DataRole[],
  users: UserProfile[],
  onCreate: (role: CreateDataRole) => void,
  usersForRole?: Record<number, string[]>
};

function Roles({roles, users, usersForRole, onCreate}: RolesProps) {
  const [showCreate, setShowCreate] = useState(false);
  const [editUsersForRole, setEditUsersForRole] = useState<DataRole | null>(null);
  const [editConditionsForRole, setEditConditionsForRole] = useState<DataRole | null>(null);
  const dispatch = useDispatch();

  const handleCreateRole = (role: CreateDataRole) => {
    setShowCreate(false);
    onCreate(role);
  }

  const handleUpdateConditions = (updated: Condition[]) => {
    if (!editConditionsForRole) return;

    dispatch(updateRole({
      id: editConditionsForRole.id,
      conditions: updated
    }));
    setEditConditionsForRole(null);
  }

  const handleAddUser = (user: UserProfile) => {
    if (editUsersForRole) {
      dispatch(addUserToRole(editUsersForRole, user.id));
    }
  }

  const handleRemoveUser = (user: UserProfile) => {
    if (editUsersForRole) {
      dispatch(removeUserFromRole(editUsersForRole, user.id));
    }
  }

  useEffect(() => {
    if (editUsersForRole) {
      dispatch(fetchUsersForRole(editUsersForRole));
    }
  }, [dispatch, editUsersForRole]);

  return (
    <>
      {showCreate && <CreateRoleModal onSave={handleCreateRole} onClose={() => setShowCreate(false)}/>}
      {editUsersForRole && <UpdateRoleModal
          role={editUsersForRole}
          users={users}
          usersForRole={usersForRole?.[editUsersForRole.id] || []}
          onAddUser={handleAddUser}
          onRemoveUser={handleRemoveUser}
          onClose={() => setEditUsersForRole(null)}/>}
      {editConditionsForRole && <EditConditionsModal
          role={editConditionsForRole}
          onClose={() => setEditConditionsForRole(null)}
          onSave={handleUpdateConditions}
        />
      }
      <PageHeading title="Roles">
        <Button
          onClick={() => setShowCreate(true)}
          type="primary"
          size="large"
          iconAfter={<AddIcon/>}
        >
          New Role
        </Button>
      </PageHeading>
      <Stack padding={"small"}/>
      <Table>
        <thead>
        <Table.Row>
          <Table.Header width="auto" sortable>
            Role Name
          </Table.Header>
          <Table.Header width="auto">Conditions</Table.Header>
          <Table.Header width="200" align="left"></Table.Header>
        </Table.Row>
        </thead>
        <tbody>
        {roles?.map((role, index) => (
          <Table.Row key={index}>
            <Table.Cell>{role.name}</Table.Cell>
            <Table.Cell>
              <Stack direction="row" wrap gap="4">
                {role.conditions?.map((condition, index) => (
                  <Conditions key={index} condition={condition}/>
                ))}
              </Stack>
            </Table.Cell>
            <Table.Cell align="right">
              <Stack direction="row" gap="4" justify={"end"}>
                <Button
                  type="tertiary"
                  size="small"
                  onClick={() => setEditUsersForRole(role)}
                >
                  View Team
                </Button>
                <Button
                  type="tertiary"
                  size="small"
                  onClick={() => setEditConditionsForRole(role)}
                >
                  Edit Filters
                </Button>
              </Stack>
            </Table.Cell>
          </Table.Row>
        ))}
        </tbody>
      </Table>
    </>
  );
}

type ConditionProps = {
  condition: Condition;
}

function Conditions({condition}: ConditionProps) {
  return (
    <Badge>
      {condition.type}{condition.field ? `.${condition.field}` : ``}: {condition.value}
    </Badge>
  );
}

export default Roles;
