import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./Routes";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import GroupSwitchModalWrapper from "./GroupSwitchModalWrapper";

import { Main as MainAdagio } from "@operata/adagio";
import MainSidebar from "./Sidebar";
import "./Main.scss";
import IntercomHelp from "../IntercomHelp";
import { useFeatureFlags } from "../../FeatureFlags";

let MainWrapper = ({ sidebarExpanded, children }) => {
  return (
    <MainAdagio className="main__wrapper" isExpanded={sidebarExpanded}>
      {children}
    </MainAdagio>
  );
};

MainWrapper.propTypes = {
  sidebarExpanded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

// eslint-disable-next-line no-class-assign
MainWrapper = connect(
  state => ({
    sidebarExpanded: state.page.sidebarExpanded
  }),
  {}
)(MainWrapper);

const Main = () => {
  const featureFlags = useFeatureFlags();

  return (
    <>
      <CssBaseline />
      <MainWrapper>
        <MainSidebar />
        <Routes />
        {featureFlags.sidebarPlaybooks && <IntercomHelp />}
      </MainWrapper>
      <GroupSwitchModalWrapper />
    </>
  );
};

export default Main;
