import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import ConfigEditor from "./ConfigEditor";
import {
  auth,
  USER_ROLE_ADMIN,
  USER_ROLE_SUPER,
  USER_ROLE_USER
} from "../../auth/Auth";
import GSMConfigs from "./GSM/GSMConfigs";
import EventBridge from "./EventBridge/EventBridge";
import { FEATURES, featureToggles } from "../../auth/FearureToggles";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="panel__wrapper"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div className="panel__content">{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`
  };
}

function isConfigEnabled(config, availableConfigs) {
  return availableConfigs.includes(config);
}

function getAvailableConfigs(configs) {
  var availableConfigs = [];

  for (const config in configs) {
    if (configs[config]) {
      availableConfigs.push(config);
    }
  }
  return availableConfigs;
}

const Configs = () => {
  const configs = {
    SOFTPHONE_MODULE: true,
    GENESYS_MODULE: true,
    NICE_MODULE: true,
    SIMULATE_MODULE: featureToggles.isFeatureEnabled(FEATURES.SIMULATE_MODULE),
    API_MODULE: auth.hasPermission([USER_ROLE_SUPER, USER_ROLE_ADMIN]),
    GSM_MODULE: auth.hasPermission([USER_ROLE_SUPER]),
    HEARTBEAT_MODULE: auth.hasPermission([USER_ROLE_SUPER]),
    EVENTBRIDGE_MODULE: featureToggles.isFeatureEnabled(
      FEATURES.EVENTBRIDGE_MODULE
    ),
    DATA_SOURCES_MODULE: true,
    AGENT_ALERTS_MODULE: true
  };

  let availableConfigs = getAvailableConfigs(configs);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div className="tabs__wrapper--vertical">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className="tabs__nav"
        >
          {isConfigEnabled("SOFTPHONE_MODULE", availableConfigs) && (
            <Tab
              className="tabs__label"
              label="Softphone"
              {...a11yProps(availableConfigs.indexOf("SOFTPHONE_MODULE"))}
            />
          )}
          {isConfigEnabled("GENESYS_MODULE", availableConfigs) && (
            <Tab
              className="tabs__label"
              label="Genesys"
              {...a11yProps(availableConfigs.indexOf("GENESYS_MODULE"))}
            />
          )}
          {isConfigEnabled("NICE_MODULE", availableConfigs) && (
            <Tab
              className="tabs__label"
              label="NiCE"
              {...a11yProps(availableConfigs.indexOf("NICE_MODULE"))}
            />
          )}
          {isConfigEnabled("SIMULATE_MODULE", availableConfigs) && (
            <Tab
              className="tabs__label"
              label="Simulate"
              {...a11yProps(availableConfigs.indexOf("SIMULATE_MODULE"))}
            />
          )}
          {isConfigEnabled("API_MODULE", availableConfigs) && (
            <Tab
              className="tabs__label"
              label="API"
              {...a11yProps(availableConfigs.indexOf("API_MODULE"))}
            />
          )}
          {isConfigEnabled("GSM_MODULE", availableConfigs) && (
            <Tab
              className="tabs__label"
              label="GSM"
              {...a11yProps(availableConfigs.indexOf("GSM_MODULE"))}
            />
          )}
          {isConfigEnabled("HEARTBEAT_MODULE", availableConfigs) && (
            <Tab
              className="tabs__label"
              label="Heartbeat"
              {...a11yProps(availableConfigs.indexOf("HEARTBEAT_MODULE"))}
            />
          )}
          {isConfigEnabled("EVENTBRIDGE_MODULE", availableConfigs) && (
            <Tab
              className="tabs__label"
              label="EventBridge"
              {...a11yProps(availableConfigs.indexOf("EVENTBRIDGE_MODULE"))}
            />
          )}

          <Tab
            className="tabs__label"
            label="Data Sources"
            {...a11yProps(availableConfigs.indexOf("DATA_SOURCES_MODULE"))}
          />
          <Tab
            className="tabs__label"
            label="Agent Alerts"
            {...a11yProps(availableConfigs.indexOf("AGENT_ALERTS_MODULE"))}
          />
        </Tabs>
        <TabPanel
          value={value}
          index={availableConfigs.indexOf("SOFTPHONE_MODULE")}
        >
          <ConfigEditor
            fields={[
              {
                type: `string`,
                key: `AWS_CONNECT_CCP`,
                title: `Amazon Connect CCP`,
                fieldText: `Amazon Connect CCP`,
                description: `The Customers Amazon Connect CCP Access URL. Correct format should be https://[instance name].awsapps.com/connect/ccp or https://[instance name].connect.aws/ccp or https://[instance name].connect.aws/connect/ccp`,
                default: "",
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER],
                hide: true
              },
              {
                type: `boolean`,
                key: `CUSTOM_SOFTPHONE`,
                title: `Media Handled by Custom Softphone?`,
                fieldText: `Media Handled by Custom Softphone?`,
                description: `If the media is handled by the default Amazon Connect CCP, leave this as disabled`,
                default: false,
                write: [USER_ROLE_SUPER],
                read: [USER_ROLE_USER, USER_ROLE_ADMIN]
              },
              {
                type: `boolean`,
                key: `MESSENGER_IN_CCP`,
                title: `Load Agent Messenger in Native Amazon softphone?`,
                fieldText: `Load Agent Messenger in Native Amazon softphone?`,
                description: `Disable this if the Amazon CCP is hosted inside another page/Iframe`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `array`,
                key: `SOFTPHONE_WHITELISTED_ORIGINS`,
                title: `Approved Origins`,
                fieldText: `Approved Origins`,
                description: `Add the origin(s) (scheme + host + port) of the Customer Softphone(s)`,
                write: [USER_ROLE_SUPER],
                read: [USER_ROLE_SUPER, USER_ROLE_ADMIN]
              },
              {
                type: `string`,
                key: `SOFTPHONE_OPERATA_API_SERVER`,
                title: `Operata API Server.`,
                fieldText: `Operata API Server.`,
                description: `Operata API Server. Eg: https://api.operata.io`,
                default: `https://api.operata.io`,
                write: [USER_ROLE_SUPER],
                read: [USER_ROLE_SUPER],
                hide: true
              },
              {
                type: `string`,
                key: `SOFTPHONE_OPERATA_STATIC_SERVER`,
                title: `Operata Static Server`,
                fieldText: `Operata Static Server`,
                description: `Operata Static Server. Eg: https://static.operata.io`,
                default: `https://static.operata.io/`,
                write: [USER_ROLE_SUPER],
                read: [USER_ROLE_SUPER],
                hide: true
              },
              {
                type: `list`,
                key: `SOFTPHONE_CONNECT_RTC_VERSION`,
                title: `Connect RTC Version`,
                fieldText: `Connect RTC Version`,
                description: `Version of the connect rtc library. Eg: v1.1.7.`,
                default: `v1.1.7`,
                options: [`v1.1.7`, `v1.1.6`, `v1.1.5`, `v1.1.4`],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER],
                hide: true
              },
              {
                type: `list`,
                key: `SOFTPHONE_CONNECT_STREAMS_VERSION`,
                title: `Connect Streams`,
                fieldText: `Connect Streams`,
                description: `Version of the connect streams library. Eg: 1.5.1.`,
                default: `1.5.1`,
                options: [`1.5.1`, `1.5.0`, `1.4.9`, `1.4.8`],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER],
                hide: true
              },
              {
                type: `boolean`,
                key: `SOFTPHONE_LOAD_AGENT_MESSENGER`,
                title: `Load Agent Messenger?`,
                fieldText: `Load Agent Messenger?`,
                description: `Load Agent Messenger?.`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `list`,
                key: `SOFTPHONE_CCP_LOG_COLLECTION`,
                title: `CCP Log Collection`,
                fieldText: `CCP Log Collection`,
                description: `Enable CCP log collection on a specific log level`,
                default: "",
                options: [
                  { value: "", label: "OFF" },
                  "TRACE",
                  "DEBUG",
                  "INFO",
                  "LOG",
                  "WARN",
                  "ERROR",
                  "CRITICAL"
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `string`,
                key: `SOFTPHONE_SSO_LOGIN_URL`,
                title: `SSO Login URL`,
                fieldText: `SSO Login URL`,
                description: `SSO Login URL (Optional).`,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `list`,
                key: `REGIONAL_AGENT_PROVIDER`,
                title: `Virtual Agent SSO Provider`,
                fieldText: `Virtual Agent SSO Provider`,
                description: `The SSO provider that the Virtual Agent will be configured to use for login. Turned off by default`,
                default: "",
                options: [{ value: "", label: "OFF" }, "OKTA", "AZURE"],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `boolean`,
                key: `SOFTPHONE_HEADSET_STATS_COLLECTION`,
                title: `Headset stats collection`,
                fieldText: `Enable headset stats collection?`,
                description: `Enable stats collection from Jabra headsets?`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `boolean`,
                key: `SOFTPHONE_JABRA_CALL_CONTROLS`,
                title: `Jabra device call controls`,
                fieldText: `Enable Jabra device call controls?`,
                description: `Disable this if you have a custom implementation for Jabra call controls`,
                default: true,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `boolean`,
                key: `SOFTPHONE_CALLER_ID`,
                title: `Interaction Metadata`,
                fieldText: `Customer Endpoint (CLI)`,
                description: `Enables additional call attributes to be attached to the Operata contact record and used within Operata Canvas for reporting`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `array`,
                key: `START_OF_DAY_TESTING_NUMBER`,
                title: `Start of day testing number`,
                fieldText: `Start of day testing number`,
                description: `Number used to run Star of Day test`,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER],
                default: ``
              },
              {
                type: `string`,
                key: `START_OF_DAY_TESTING_STATUS`,
                title: `Start of day testing status`,
                fieldText: `Start of day testing status`,
                description: `Status of the agent to trigger Start of Day test`,
                default: `Start of Day Test`,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              }
            ]}
          />
        </TabPanel>

        <TabPanel
          value={value}
          index={availableConfigs.indexOf("GENESYS_MODULE")}
        >
          <ConfigEditor
            fields={[
              {
                type: `json`,
                key: `GENESYS`,
                title: `Genesys Softphone Configuration`,
                description: `Genesys Softphone Configuration`,
                fields: [
                  {
                    type: `string`,
                    key: `CCAAS_ID`,
                    title: "Org Id",
                    default: ``
                  },
                  {
                    type: `string`,
                    key: `CCAAS_NAME`,
                    title: "Org Name",
                    default: ``
                  },
                  {
                    type: `string`,
                    key: `EXTERNAL_CLIENT_ID`,
                    title: "Client Id",
                    default: ``
                  },
                  {
                    type: `string`,
                    key: `EXTERNAL_CLIENT_SECRET`,
                    title: "Client Secret",
                    default: ``
                  }
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              }
            ]}
          />
        </TabPanel>

        <TabPanel value={value} index={availableConfigs.indexOf("NICE_MODULE")}>
          <ConfigEditor
            fields={[
              {
                type: `json`,
                key: `NICE`,
                title: `NiCE Softphone Configuration`,
                description: `NiCE Softphone Configuration`,
                fields: [
                  {
                    type: `string`,
                    key: `CCAAS_ID`,
                    title: "Tenant Id",
                    default: ``
                  }
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              }
            ]}
          />
        </TabPanel>

        <TabPanel
          value={value}
          index={availableConfigs.indexOf("SIMULATE_MODULE")}
        >
          <ConfigEditor
            fields={[
              {
                type: `string`,
                key: `TWILIO_SID`,
                title: `Dialler SID`,
                fieldText: `Dialler SID`,
                description: `Dialler is used to initiate inbound calls to your Amazon Connect Contact Flow`,
                write: [USER_ROLE_SUPER],
                read: [USER_ROLE_ADMIN],
                default: ``
              },
              {
                type: `string`,
                key: `TWILIO_TOKEN`,
                title: `Twilio Token`,
                fieldText: `Twilio Token`,
                description: `The Twilio Token to allow integration to the Twilio Account SID`,
                write: [USER_ROLE_SUPER],
                read: [],
                default: ``
              },
              {
                type: `string`,
                key: `TWILIO_CPS`,
                title: `Calls Per Second`,
                fieldText: `Calls Per Second`,
                description: `The purchased maximum calls per second`,
                write: [USER_ROLE_SUPER],
                read: [USER_ROLE_ADMIN, USER_ROLE_USER],
                default: ``
              },
              {
                type: `array`,
                key: `SIMULATE_WHITELISTED_TO_NUMBERS`,
                title: `Allowlisted 'To' Numbers`,
                fieldText: `Allowlisted 'To' Numbers`,
                description: `The phone numbers that can be dialled by a Simulate campaign`,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              }
            ]}
          />
        </TabPanel>
        {isConfigEnabled("API_MODULE", availableConfigs) && (
          <TabPanel
            value={value}
            index={availableConfigs.indexOf("API_MODULE")}
          >
            <ConfigEditor
              fields={[
                {
                  type: `json`,
                  key: `API_TOKENS`,
                  title: `API Tokens`,
                  fieldText: `API Tokens`,
                  description: `The API tokens used to access the Operata API`,
                  write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                  read: []
                }
              ]}
            />
          </TabPanel>
        )}
        {isConfigEnabled("GSM_MODULE", availableConfigs) && (
          <TabPanel
            value={value}
            index={availableConfigs.indexOf("GSM_MODULE")}
          >
            <GSMConfigs />
          </TabPanel>
        )}
        {isConfigEnabled("HEARTBEAT_MODULE", availableConfigs) && (
          <TabPanel
            value={value}
            index={availableConfigs.indexOf("HEARTBEAT_MODULE")}
          >
            <ConfigEditor
              fields={[
                {
                  type: `boolean`,
                  key: `HEARTBEAT_ENABLED`,
                  title: `Enable Heartbeat?`,
                  fieldText: `Enable Heartbeat?`,
                  description: `Enabling Heartbeat will load the Heartbeat/Push Service client on agent softphones`,
                  default: false,
                  write: [USER_ROLE_SUPER],
                  read: [USER_ROLE_SUPER]
                },
                {
                  type: `array`,
                  key: `HEARTBEAT_TARGET_PHONE_NUMBERS`,
                  title: `Heartbeat 'Target Phone Number'`,
                  fieldText: `Heartbeat 'Target Phone Number'`,
                  description: `The phone number that Heartbeat will dial when running a test`,
                  write: [USER_ROLE_SUPER],
                  read: [USER_ROLE_SUPER],
                  default: ``
                },
                {
                  type: `array`,
                  key: `HEARTBEAT_VIRTUAL_AGENT_NAMES`,
                  title: `Heartbeat 'Virtual Agent Names'`,
                  fieldText: `Heartbeat 'Virtual Agent Names'`,
                  description: `The comma separated names of the virtual agents that are used to run Heartbeat tests`,
                  write: [USER_ROLE_SUPER],
                  read: [USER_ROLE_SUPER],
                  default: ``
                }
              ]}
            />
          </TabPanel>
        )}
        {isConfigEnabled("EVENTBRIDGE_MODULE", availableConfigs) && (
          <TabPanel
            value={value}
            index={availableConfigs.indexOf("EVENTBRIDGE_MODULE")}
          >
            <EventBridge />
          </TabPanel>
        )}
        <TabPanel
          value={value}
          index={availableConfigs.indexOf("DATA_SOURCES_MODULE")}
        >
          <ConfigEditor
            fields={[
              {
                type: `json`,
                key: `CLOUD_COLLECTOR_VERSION`,
                title: `Cloud Collector`,
                fieldText: `Cloud Collector`,
                description: `Cloud Collector version`,
                write: [],
                read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
                default: ``
              },
              {
                type: `json`,
                key: `ORCHESTRATOR_VERSION`,
                title: `Orchestrator`,
                fieldText: `Orchestrator`,
                description: `Orchestrator version`,
                write: [],
                read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
                default: ``
              },
              {
                type: `json`,
                key: `REDACTION_CONFIG`,
                title: `CTR Redaction Config`,
                fieldText: `CTR Redaction Config`,
                description: `The redaction config used to redact CTR data collected`,
                write: [],
                read: [USER_ROLE_SUPER, USER_ROLE_ADMIN, USER_ROLE_USER],
                default: ``
              },
              {
                type: `array`,
                key: `CTR_ATTRIBUTES_WHITELIST`,
                title: `Allowlisted CTR Attributes`,
                fieldText: `Allowlisted CTR Attributes`,
                description: `The CTR attributes that can be collected`,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER],
                default: ``
              }
            ]}
          />
        </TabPanel>
        <TabPanel
          value={value}
          index={availableConfigs.indexOf("AGENT_ALERTS_MODULE")}
        >
          <ConfigEditor
            fields={[
              {
                type: `boolean`,
                key: `AGENT_ALERT_DISPLAY_TOASTS`,
                title: `Toast Notification`,
                fieldText: `Enable toast banner notifications on alerts`,
                description: `Enabling this causes a toast to pop when the agent receives an alert`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_MICROPHONE_NOT_SHARED`,
                title: `Microphone Not Shared`,
                fieldText: `Enable microphone not shared alert`,
                description: `Alert the Agent if the microphone is not shared`,
                default: "true",
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `json`,
                key: `AGENT_ALERT_THRESHOLD_MOS`,
                title: `Network Performance (MOS)`,
                fieldText: `Agent Alert Threshold - Network Performance (MOS)`,
                description: `Agent Alert Threshold - Network Performance (MOS). Defaults: Less than 3.5 for more than 10 seconds`,
                fields: [
                  {
                    type: `string`,
                    key: `low_mos`,
                    title: "Low Threshold",
                    default: "3.5"
                  },
                  {
                    type: `string`,
                    key: `very_low_mos`,
                    title: "Very Low Threshold",
                    default: `2`
                  },
                  {
                    type: `string`,
                    key: `seconds`,
                    title: "Duration",
                    default: `10`
                  }
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `json`,
                key: `AGENT_ALERT_COMPUTER_PERFORMANCE`,
                title: `Poor Computer Performance (%)`,
                fieldText: `Agent Alert Threshold - Poor Computer Performance (%)`,
                description: `Agent Alert Threshold - Poor Computer Performance (%). Defaults: More than 80% used for more than 30 seconds`,
                fields: [
                  {
                    type: `string`,
                    key: `low_performance`,
                    title: "Low Threshold",
                    default: "80"
                  },
                  {
                    type: `string`,
                    key: `very_low_performance`,
                    title: "Very Low Threshold",
                    default: "90"
                  },
                  {
                    type: `string`,
                    key: `seconds`,
                    title: "Duration",
                    default: `30`
                  }
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `string`,
                key: `AGENT_ALERT_OPTIONAL_COMMENT_PROMPT`,
                title: `Optional Comment Prompt`,
                fieldText: `Customize Optional Comment Prompt`,
                description: `Additional prompt agents will see when reporting an issue`,
                default: "",
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_USER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_JABRA_DEVICE_PROMPT`,
                title: `Jabra Headset Prompt`,
                fieldText: `Enable Jabra headset prompt`,
                description: `Enabling the Jabra device detection alert will allow agents to be prompted if they are not using their Jabra device`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_TALKING_ON_MUTE`,
                title: `Talking On Mute`,
                fieldText: `Enable talking on mute alert`,
                description: `Enabling the talking on mute alert will allow agents to be prompted if they are talking while muted`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_FREQUENT_MUTING`,
                title: `Frequent Muting`,
                fieldText: `Enable frequent muting alert`,
                description: `Enabling the frequent muting alert will prompt agents if they frequently muting their microphone`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `json`,
                key: `AGENT_ALERT_BACKGROUND_NOISE`,
                title: `Background Noise`,
                description: `Enabling the background noise very high alert will prompt agents if they are in a very high background noise environment`,
                fields: [
                  {
                    type: `boolean`,
                    key: `enabled`,
                    title: "Enabled",
                    fieldText: `Enable alert`,
                    default: false
                  },
                  {
                    type: `string`,
                    key: `high_threshold`,
                    title: "High Threshold (dB)",
                    default: `70`
                  },
                  {
                    type: `string`,
                    key: `base_threshold`,
                    title: "Base Threshold (dB)",
                    default: `55`
                  }
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_BACKGROUND_NOISE_VOLUME_UP`,
                title: `Background Noise (Volume Tap)`,
                fieldText: `Enable background noise (when volume increasing) alert`,
                description: `Enabling the background noise + volume up tap alert will prompt agents if they are in a high background noise environment and they are increasing the volume on their headset to compensate`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_BACKGROUND_NOISE_FREQUENT_MUTING`,
                title: `Background Noise (Frequent Muting)`,
                fieldText: `Enable background noise (when frequent muting) alert`,
                description: `Enabling the background noise + frequent muting alert will prompt agents if they are in a high background noise environment and they are frequently muting their microphone`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `json`,
                key: `AGENT_ALERT_CROSSTALK`,
                title: `Crosstalk`,
                fieldText: `Enable crosstalk (when it is excessive) alert`,
                description: `Enabling the excessive crosstalk alert will prompt agents if they and the customer are frequently talking over each other`,
                fields: [
                  {
                    type: `boolean`,
                    key: `enabled`,
                    title: "Enabled",
                    fieldText: `Enable alert`,
                    default: false
                  },
                  {
                    type: `string`,
                    key: `high_threshold`,
                    title: "High Threshold (%)",
                    default: `90`
                  },
                  {
                    type: `string`,
                    key: `base_threshold`,
                    title: "Base Threshold (%)",
                    default: `50`
                  }
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `json`,
                key: `AGENT_ALERT_CROSSTALK_LATENCY`,
                title: `Crosstalk (Due to High Latency)`,
                fieldText: `Enable crosstalk (due to high latency) alert`,
                description: `Enabling the crosstalk + high latency alert will prompt agents if they are talking over their customer, likely because of high latency`,
                fields: [
                  {
                    type: `boolean`,
                    key: `enabled`,
                    title: "Enabled",
                    fieldText: `Enable alert`,
                    default: false
                  },
                  {
                    type: `string`,
                    key: `threshold`,
                    title: "Threshold (ms)",
                    default: `200`
                  }
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_BOOM_ARM_POSITION`,
                title: `Boom Arm Position Incorrect`,
                fieldText: `Enable boom arm position when incorrect or mis-aligned alert`,
                description: `Enabling the boom arm position alert will prompt agents if the boom arm on their Jabra device is incorrectly positioned for the best customer experience`,
                default: false,
                write: [USER_ROLE_SUPER],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `json`,
                key: `AGENT_ALERT_AUDIO_EXPOSURE`,
                title: `Audio Exposure Warning`,
                fieldText: `Enable warning for audio exposure alert`,
                description: `Enabling the audio exposure alert will prompt agents if they are suffering from consistently high output volume`,
                fields: [
                  {
                    type: `boolean`,
                    key: `enabled`,
                    title: "Enabled",
                    fieldText: `Enable alert`,
                    default: false
                  },
                  {
                    type: `string`,
                    key: `high_threshold`,
                    title: "High Threshold (dB)",
                    default: `85`
                  }
                ],
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_BATTERY_LOW`,
                title: `Battery Level (Low)`,
                fieldText: `Enable battery level (low) alert`,
                description: `Enabling the battery low alert will prompt agents if the battery in their wireless Jabra device is low`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              },
              {
                type: `boolean`,
                key: `AGENT_ALERT_BATTERY_VERY_LOW`,
                title: `Battery Level (Very Low)`,
                fieldText: `Enable battery level (very low) alert`,
                description: `Enabling the battery very low alert will prompt agents if the battery in their wireless Jabra device is at a critical level`,
                default: false,
                write: [USER_ROLE_SUPER, USER_ROLE_ADMIN],
                read: [USER_ROLE_SUPER]
              }
            ]}
          />
        </TabPanel>
      </div>
    </React.Fragment>
  );
};

export default Configs;
