import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { ButtonIntercom } from '@operata/adagio';
import { CSSTransition } from 'react-transition-group';
import './styles.scss';

interface ArticleMapping {
  regex: RegExp;
  articleId: number;
  buttonText: string;
}

const urlToIntercomArticleMap: ArticleMapping[] = [
  {
    regex: /^\/[0-9a-f-]+\/canvas\/default_/,
    articleId: 10078613,
    buttonText: 'Learn more about the Overview dashboard',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Technology-NetworksApplicationsEquipment$/,
    articleId: 10078619,
    buttonText: 'Learn more about the Network, Applications and Equipment dashboard',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Technology-ReportedIssues$/,
    articleId: 10078622,
    buttonText: 'Learn about reported issues and insights',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Technology-CallOverview$/,
    articleId: 10078613,
    buttonText: 'Explore the call overview',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Technology-AgentOverview$/,
    articleId: 10078630,
    buttonText: 'Learn about the agent overview',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Technology-CohortComparison$/,
    articleId: 10078632,
    buttonText: 'Understand and compare cohorts effectively',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Technology-LogsAndErrors$/,
    articleId: 10078636,
    buttonText: 'Learn about logs and errors',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Technology-CollectionCoverage$/,
    articleId: 10078637,
    buttonText: 'Get clued up about collection coverage',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Operations-AgentInteractions$/,
    articleId: 10078639,
    buttonText: 'Acquire an understanding of agent interactions',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Operations-CustomerExperience$/,
    articleId: 10078640,
    buttonText: 'Become proficient in customer experience',
  },
  {
    regex: /^\/[0-9a-f-]+\/canvas\/[0-9a-f-]+_Operations-ConversationalAnalytics$/,
    articleId: 10078642,
    buttonText: 'Get the hang of conversational analytics',
  },
  {
    regex: /^\/[0-9a-f-]+\/explorer\/*/,
    articleId: 10078642,
    buttonText: 'Stay on top of the latest calls',
  },
];

interface ArticleInfo {
  articleId: number;
  buttonText: string;
}

const IntercomHelp: React.FC = () => {
  const [articleInfo, setArticleInfo] = useState<ArticleInfo | null>(null);
  const [showHelp, setShowHelp] = useState(false);
  const location = useLocation();
  const nodeRef = useRef(null);
  const timerRef = useRef<number | null>(null);
  const [fadeOut, setFadeOut] = useState(false);


  const checkUrlForArticle = () => {
    const currentUrl = window.location.pathname;
    const match = urlToIntercomArticleMap.find(mapping => mapping.regex.test(currentUrl));
    setArticleInfo(match ? { articleId: match.articleId, buttonText: match.buttonText } : null);

    if (match) {
      setShowHelp(true);
    }
  };

  const handleShowArticle = () => {
    if (window.Intercom && articleInfo) {
      window.Intercom('showArticle', articleInfo.articleId);
      setShowHelp(false);
    } else {
      console.error('[Operata] Intercom article could not be loaded')
    }
  };

  useEffect(() => {
    // Trigger exit animation
    if (showHelp) {
      setFadeOut(true);
    } else {
      checkUrlForArticle();
      setShowHelp(true);
    }

    // Timer logic for toast
    if (timerRef.current !== null) {
      clearTimeout(timerRef.current as number);
    }
    timerRef.current = window.setTimeout(() => setShowHelp(false), 30000);
    return () => {
      if (timerRef.current !== null) {
        clearTimeout(timerRef.current as number);
      }
    };
  }, [location.pathname]);

  useEffect(() => {
    if (fadeOut) {
      const fadeOutTimer = setTimeout(() => {
        setFadeOut(false);
        checkUrlForArticle();
      }, 500); // This timeout needs to match the CSS transition duration

      return () => clearTimeout(fadeOutTimer);
    }
  }, [fadeOut]);

  return (
    <CSSTransition
      in={!fadeOut && showHelp}
      timeout={500}
      classNames="intercom-fade"
      nodeRef={nodeRef}
      unmountOnExit
    >
      <div className="intercom-help-wrapper" ref={nodeRef}>
        {articleInfo && (
          <ButtonIntercom onClick={handleShowArticle}>
            {articleInfo.buttonText}
          </ButtonIntercom>
        )}
      </div>
    </CSSTransition>
  );
};

export default IntercomHelp;
